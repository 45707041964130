define('m01new/controllers/supercontroller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    auswertungBerechnungsergebnis: function auswertungBerechnungsergebnis(data) {
      var self = this;
      var application = self.controllerFor('application');
      var lasteinwirkung = self.controllerFor(application.get('lasteinwirkungsReiter'));
      var vmittel = self.controllerFor('verbindungsmittel');

      if (Number(JSON.parse(data).Item1) === 0) {
        var liste = JSON.parse(JSON.parse(data).Item2);
        lasteinwirkung.verbindungsmittelliste = liste;
        var core = treetools.buildTree(liste, true);
        vmittel.set('schraubenliste', core);
        vmittel.set('keineSchraubenliste', false);

        if (application.get('bauder') && application.get('bauderMaster') === false) {
          if (liste.findBy('VM_Okay', "True")) {
            lasteinwirkung.set('ergebnisschraube', liste.findBy('VM_Okay', "True"));
            application.set('verbindungsmittelInvalid', false);
            vmittel.set('screwchanged', !vmittel.get('screwchanged'));
          } else {
            lasteinwirkung.set('ergebnisschraube', { "ID": "9999", "ArtNr": "", "VM_Name": " Kein passendes Verbindungsmittel verfügbar", "Kopfform": "", "d_1": "", "l_s": "" });
            application.set('verbindungsmittelInvalid', true);
            vmittel.set('screwchanged', !vmittel.get('screwchanged'));
          }
        }
      } else if (Number(JSON.parse(data).Item1) === 1) {
        application.set('verbindungsmittelInvalid', false);
        application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', true);
        vmittel.set('infoText', JSON.parse(data).Item3);
        vmittel.set('keineSchraubenliste', true);
      }
    },

    erzeugeAufrufzusatz: function erzeugeAufrufzusatz(zweiterzusatz) {

      // Aufrufe	Zusatz1	Zusatz2
      // initialisiereBerechnung	"?"+kennungland_loadsNA_timberNA
      // getKompaktergebnis	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // erzeugeSchraubenliste	"?"+kennungland_loadsNA_timberNA
      // erzeugeAusgabe	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // erzeugeDXF	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // speichereBerechnung	"?"+kennungland_loadsNA_timberNA
      //
      // Beispiel
      // erzeugeAusgabe/?CH_CH_DE_0170+350+45

      var self = this;

      var application = self.controllerFor('application');
      var applicationdata = application.get('model').application.objectAt(0);
      var verbindungsmittel = self.controllerFor('verbindungsmittel');

      var zusatzString = "?" + applicationdata.get("kennungland") + "_" + applicationdata.get("loadsNA") + "_" + applicationdata.get("timberNA");

      if (zweiterzusatz === true) {

        var artikelnummer;

        if (typeof verbindungsmittel.get('selectedNodes')[0] === 'undefined') {
          artikelnummer = "Keine Artikel ausgewaehlt";
        } else {
          artikelnummer = verbindungsmittel.get('selectedNodes')[0].data.ArtNr;
        }

        zusatzString = zusatzString + "_" + artikelnummer.replace(/ /g, '+');
      }

      return zusatzString;
    },

    getTimberelementGl: function getTimberelementGl() {
      var self = this;
      var application = self.controllerFor('application');
      var daemmungdata = application.get('model').daemmung.objectAt(0);

      var timberelementgl = {
        "Querschnittswerte": {
          "QuerschnittsID": daemmungdata.get('QuerschnittsID'),
          "t_Fastener": daemmungdata.get('glHoehe'),
          "b": daemmungdata.get('glBreite'),
          "h": daemmungdata.get('glHoehe')
        },
        "alphaRAD_KraftFaser": "0.0",
        "alphaRAD_vmFaser": "90.0",
        "betaRAD_vmFurnier": "90.0",
        "vorgebohrt": false,
        "MatKey": daemmungdata.get('MatKeyTimberElementGl'),
        "FKLKey": daemmungdata.get('FKLKeyTimberElementGl')

      };

      return timberelementgl;
    },

    getTimberelementSp: function getTimberelementSp() {
      var self = this;
      var application = self.controllerFor('application');
      var unterkonstruktiondata = application.get('model').unterkonstruktion.objectAt(0);

      var timberelementsp = {
        "Querschnittswerte": {
          "QuerschnittsID": unterkonstruktiondata.get('QuerschnittsID'),
          "t_Fastener": unterkonstruktiondata.get('TimberElementSp_h'),
          "b": unterkonstruktiondata.get('TimberElementSp_b'),
          "h": unterkonstruktiondata.get('TimberElementSp_h')
        },
        "alphaRAD_KraftFaser": "0.0",
        "alphaRAD_vmFaser": "90.0",
        "betaRAD_vmFurnier": "90.0",
        "vorgebohrt": false,
        "MatKey": unterkonstruktiondata.get('MatKeyTimberElementSp'),
        "FKLKey": unterkonstruktiondata.get('FKLKeyTimberElementSp')
      };

      return timberelementsp;
    },

    logaufruf: function logaufruf(_aufruf, _status, _showOutput, _logAnServerSendenErzwingen) {
      var self = this;
      var application = self.controllerFor('application');
      var applicationdata = application.get('model').application.objectAt(0);

      var date = new Date();
      var datuminticks = date.getTime() * 10000 + 621355968000000000;

      if (_showOutput) {
        console.log("***** logaufruf von *****");
        console.log(_aufruf);
      }

      var tc = application.get('model').application.objectAt(0).get('timberCode').replace(" ", "").substring(0, 3);
      var lc = application.get('model').application.objectAt(0).get('loadsCode').replace(" ", "").substring(0, 3);

      var guid = "00000000-0000-0000-0000-000000000000";
      var guidlength = guid.length;
      var cidlength = application.get('model').application.objectAt(0).get('Calculation_ID').length;
      guid = guid.substring(0, guidlength - cidlength) + application.get('model').application.objectAt(0).get('Calculation_ID');

      var logdaten = {
        "datum": datuminticks,
        "bid": guid,
        "request": 0,
        "firma": enums.Firma.findBy("bezeichnung", application.get('model').application.objectAt(0).get('firm')).id,
        "modul": enums.Modul.findBy("bezeichnung", "m01").id,
        "bemessung": enums.NormBemessung.findBy("bezeichnung", tc).id,
        "bemessungNAD": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('timberNA').toLowerCase()).id,
        "lastannahmen": enums.NormLastannahmen.findBy("bezeichnung", lc).id,
        "lastannahmenNAD": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('loadsNA').toLowerCase()).id,
        "eingabeSprache": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('spracheinput').toLowerCase()).id,
        "ausgabeSprache": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('spracheoutput').toLowerCase()).id,
        "kennungLand": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('kennungland').toLowerCase()).id,
        "katalog": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('katalog').toLowerCase()).id,
        "methode": enums.Methode.findBy("bezeichnung", _aufruf).id,
        "userIp": null,
        "userName": application.get('model').application.objectAt(0).get('userName'),
        "url": null,
        "status": _status,
        "artikelnummer": application.get('model').application.objectAt(0).get('selectedScrew')
      };

      if (_showOutput) {
        console.log("zu lockende Daten:");
        console.log(logdaten);
      }

      var form = new FormData();
      form.append("", JSON.stringify(logdaten));

      var environment = externalConfig.environments[application.get('environment')];
      var url = externalConfig[environment].uriLogservice;

      var dev = true;

      var liveVersion = application.get('environment').search('dev') !== -1 ? false : true;

      liveVersion = application.get('bauder') === true ? true : liveVersion;

      if (liveVersion === true || _logAnServerSendenErzwingen === true) {

        $.ajax({
          "type": "POST",
          "url": url,
          "processData": false,
          "contentType": false,
          data: form

        }).done(function (data, statusText, xhr) {
          if (_showOutput) {
            console.log("Daten gelockt: " + data);
            // var status = xhr.status;                //200
            // console.log("statusText");
            // console.log(statusText);
            // console.log("status");
            // console.log(status);
            console.log(" ");
          }
        });
      }
      // testen von der Browserkonsole --> M01new.__container__.lookup('controller:supercontroller').logaufruf('erzeugeDXF', 4444, true, true);
    }

  });

});